import { Link } from "react-scroll";
import React from "react";
export default function HeroSection() {
  return (
    <section id="heroSection" className="hero--section">
      <div className="hero--section--content--box">
        <div className="hero--section--content">
          <p className="section--title">Hello, I'm Chris</p>
          <h1 className="hero--section--title">
            <span className="hero--section-title--color">Software</span>{" "}
            <br />
            Developer
          </h1>
          <p className="hero--section-description">
          I design and build high-quality software solutions that drive results. 
            <br /> I’m excited about a career where I can use my creativity to build stunning software and engaging experiences, and I look forward to working with talented teams to create something amazing together.
          </p>
        </div>
        <button className="btn btn-primary">
        <Link
                activeClass="navbar--active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to="Contact"
                className="btn -outline-primary"
              >
                Get In Touch
              </Link></button>
      </div>
      <div className="hero--section--img">
        <img src="./img/hero_img.png" alt="Hero Section" />
      </div>
    </section>
  );
}
