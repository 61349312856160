

export default function Testimonials() {
  return (
    <section id="Testimonials" className="testimonial--section">
      <div className="testimonial--section--content">
        <h1 className="testimonial--section--heading">My Experience</h1>
        <p className="testimonial--section--text">
          I have 3 years of programming experience gained through my studies at Nottingham Trent University, where I developed a robust MERN stack web application to optimise restaurant stock management. This project enhanced my skills in JavaScript, MongoDB, and Express. Leading software testing projects, I successfully reduced bug counts by 20%, ensuring a smoother user experience. Also, as part of my coursework, I created an e-transport marketplace to streamline cargo and logistics operations, addressing real-world challenges.<br></br> <br></br>Additionally, I participated in projects where we conceptualised solutions for real-world clients, and after successfully pitching these ideas, had the option to develop the final products in my final year. My active involvement in the university's Dev Society allowed me to collaborate on innovative projects, further sharpening my technical and team collaboration skills. My passion for software development and IT, coupled with a commitment to continuous learning and professional growth, drives me to deliver high-quality, user-centric solutions.
        </p>
      </div>
    </section>
  );
}
