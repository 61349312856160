export default function AboutMe() {
  return (
    <section id="AboutMe" className="about--section">
      <div className="about--section--img">
        <img src="./img/about-me.png" alt="About Me" />
      </div>
      <div className="hero--section--content--box about--section--box">
        <div className="hero--section--content">
          <h1 className="skills-section--heading">About Me</h1>
          <p className="hero--section-description">
            Hi my name is Chris and I am a software developer. I'm passionate about everything IT-related and software development. Whether it's designing user-friendly interfaces, coding efficient applications, or managing complex IT systems, I thrive on the challenge of turning ideas into reality.
          </p>
          <p className="hero--section-description">
            When im not behind the screen i enjoy playing Football, Tennis , Chess and Running :)
          </p>
        </div>
      </div>
    </section>
  );
}
